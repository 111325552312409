<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div class="d-flex flex-row">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          :id="'iiotDeployLogTableIconBtn_'+ tableItem.id"
          :class="[`${tableItem.type}-btn-color`, 'mr-2', 'mb-2']"
          style="width: 20px; height: 20px;"
          elevation="0"
          fab
          x-small
          v-on="on"
        >
          <img
            v-if="tableItem.type === 'docker-compose'"
            :src="`/img/${tableItem.type}.svg`"
            class="ml-5"
          />
          <v-icon
            x-small>
            {{`$vuetify.icons.${tableItem.type}`}}
          </v-icon>
        </v-btn>
      </template>
      <span>{{$t(`deployLog.list.${tableItem.type}`)}}</span>
    </v-tooltip>

    <div class="ml-1 mr-2 mb-1">
      <div @mouseover="isTooltip($event)">
        <v-tooltip
          v-if="tooltip"
          bottom>
          <template v-slot:activator="{ on }">
            <div
              id="iiotDeployLogTableIconLabel"
              v-on="on"
              class="cursor-pointer"
            >
              {{ tableItem.name }}
            </div>
          </template>
          <span>{{ tableItem.name }}</span>
        </v-tooltip>
        <div v-else>
          <span>{{ tableItem.name }}</span>
        </div>
      </div>
    </div>

    <v-tooltip
      v-if="tableItem.action"
      bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          :id="'iiotDeployLogTableActionBtn_'+ tableItem.id"
          style="width: 18px; height: 18px;"
          elevation="0"
          fab
          x-small
          v-on="on"
        >
          <v-img
            v-on="on"
            :src="`/img/DryRun.svg`"
            height="18px"
            width="18px"
          />
        </v-btn>
      </template>
      <span>{{$t(`deployLog.list.dryRun`)}}</span>
    </v-tooltip>

  </div>
</template>

<script>
export default {
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tooltip: true,
    };
  },
  methods: {
    isTooltip(event) {
      this.tooltip = event.target.scrollWidth > event.target.offsetWidth;
    },
  },
};
</script>
<style scoped>
.docker-btn-color {
  background-image: linear-gradient(to bottom, #ff9a00, #ff6100) !important;
}

.vm-btn-color {
  background-image: linear-gradient(to top, #3810f2, #6f42c1) !important;
}

.codesys-btn-color {
  background-image: linear-gradient(to top, #dc3545, #e83e8c) !important;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
